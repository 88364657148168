import { useEffect, useState, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import generateUUID from "utils/generateUUID";
import useQualitySheet from "components/Delivery/QualitySheet/useQualitySheet";
import { qualitySheetSchema } from "components/Delivery/QualitySheet/Validation/schemaValidation";
import { getValidationErrors } from "components/Delivery/QualitySheet/Validation/getValidationErrors";
import useAppTitle from "utils/useAppTitle";
import { cancelScreenTimeout } from "utils/config";
import { utcNow } from "utils/date";
import useNetworkStatus from "utils/useNetworkStatus";

import { VALIDATE_ORDER, makeValidateOrderOptions } from "./validateOrder";
import { DEFAULT_PICKING_ARTICLE_DATA } from "pages/Picking/usePicking";

const DEFAULT_DELIVERY_ARTICLE_DATA = {
  is_delivered: false,
  failure_reason: "",
};

const generateArticleMetadata = (article) => {
  const numberOfMetadataToBeGenerated =
    article.quantity - article.metadata.length;

  const alreadyGeneratedMetadata = article.metadata.map((metadatum) => ({
    ...DEFAULT_DELIVERY_ARTICLE_DATA,
    ...metadatum,
  }));

  /* 
    There is (the same amount of/more) metadata than the current article quantity,
    in this case we will return the metadata already contained in article
  */
  if (numberOfMetadataToBeGenerated <= 0) {
    return alreadyGeneratedMetadata;
  }

  /* 
    Some metadata are already generated but we miss some,
    in this case we will return the already generated metadata and generate the 
    missing ones
  */
  if (
    numberOfMetadataToBeGenerated > 0 &&
    alreadyGeneratedMetadata.length > 0
  ) {
    return [
      ...Array.apply(null, Array(numberOfMetadataToBeGenerated)).map(() => ({
        metadata_id: generateUUID(),
        order_article_id: article.id,
        ...DEFAULT_DELIVERY_ARTICLE_DATA,
        ...DEFAULT_PICKING_ARTICLE_DATA,
      })),
      ...alreadyGeneratedMetadata,
    ];
  }

  /* There is not metadata associated with the article : we generate them all */
  return Array.apply(null, Array(article.quantity)).map(() => ({
    metadata_id: generateUUID(),
    order_article_id: article.id,
    ...DEFAULT_DELIVERY_ARTICLE_DATA,
    ...DEFAULT_PICKING_ARTICLE_DATA,
  }));
};

const generateDefaultOrderArticles = (articles) => {
  return articles.reduce((acc, article) => {
    const orderArticle = {
      ...article,
      /* 
        is_delivered and failure_reason are only used to determine what status should be applied to the delivery article children
        thoses attributes should not be included in the final request
      */
      isDelivered: false,
      failureReason: "",
      metadata: generateArticleMetadata(article),
    };

    delete orderArticle.__typename;

    return {
      ...acc,
      [article.id]: orderArticle,
    };
  }, {});
};

const useOrderArticles = (articles) => {
  const defaultOrderArticles = useMemo(
    () => generateDefaultOrderArticles(articles),
    [articles]
  );
  const [orderArticles, setOrderArticles] = useState(defaultOrderArticles);

  const resetOrderArticles = () => {
    setOrderArticles(defaultOrderArticles);
  };

  const setOrderArticleDeliveryStatus = (orderArticleId, isDelivered) => {
    const updatedOrderArticles = { ...orderArticles };

    updatedOrderArticles[orderArticleId] = {
      ...updatedOrderArticles[orderArticleId],
      isDelivered,
      metadata: updatedOrderArticles[orderArticleId].metadata.map(
        (metadatum) => ({
          ...metadatum,
          is_delivered: isDelivered,
        })
      ),
    };

    setOrderArticles(updatedOrderArticles);
  };

  const setOrderArticleFailureReason = (orderArticleId, failureReason) => {
    const updatedOrderArticles = { ...orderArticles };

    updatedOrderArticles[orderArticleId] = {
      ...updatedOrderArticles[orderArticleId],
      failureReason,
      metadata: updatedOrderArticles[orderArticleId].metadata.map(
        (metadatum) => ({
          ...metadatum,
          failure_reason: failureReason,
        })
      ),
    };

    setOrderArticles(updatedOrderArticles);
  };

  const setOrderArticleMetadataDeliveryStatus = (
    orderArticleId,
    orderArticleMetadatumId,
    isDelivered
  ) => {
    const updatedOrderArticles = { ...orderArticles };

    const metadatumToUpdateIndex = updatedOrderArticles[
      orderArticleId
    ].metadata.findIndex(
      (metadatum) => metadatum.metadata_id === orderArticleMetadatumId
    );

    updatedOrderArticles[orderArticleId].metadata[
      metadatumToUpdateIndex
    ].is_delivered = isDelivered;

    setOrderArticles(updatedOrderArticles);
  };

  const setOrderArticleMetadataFailureReason = (
    orderArticleId,
    orderArticleMetadatumId,
    failureReason
  ) => {
    const updatedOrderArticles = { ...orderArticles };

    const metadatumToUpdateIndex = updatedOrderArticles[
      orderArticleId
    ].metadata.findIndex(
      (metadatum) => metadatum.metadata_id === orderArticleMetadatumId
    );

    updatedOrderArticles[orderArticleId].metadata[
      metadatumToUpdateIndex
    ].failure_reason = failureReason;

    if (failureReason !== updatedOrderArticles[orderArticleId].failureReason) {
      updatedOrderArticles[orderArticleId].failureReason = "";
    }

    setOrderArticles(updatedOrderArticles);
  };

  return {
    orderArticles,
    setOrderArticles,
    setOrderArticleDeliveryStatus,
    setOrderArticleFailureReason,
    setOrderArticleMetadataDeliveryStatus,
    setOrderArticleMetadataFailureReason,
    resetOrderArticles,
  };
};

const preparePhotosForSubmit = (photos) => {
  return JSON.stringify(
    photos
      .map((photo) =>
        photo["remove"]
          ? null
          : photo["new"]
          ? photo
          : {
              // If photo is not new, do not resend base64 image, we already have it
              id: photo["id"],
            }
      )
      .filter((p) => p && typeof p === "object") // Remove null data
  );
};

const useValidPaymentPhotos = (payments, paymentPhotos) => {
  const [hasValidPaymentPhotos, setHasValidPaymentPhotos] = useState(false);

  useEffect(() => {
    const validPayments = payments.filter((payment) => payment.paid_amount > 0);
    if (!validPayments.length) {
      setHasValidPaymentPhotos(true);
    } else {
      setHasValidPaymentPhotos(!!paymentPhotos.find((photo) => !photo.remove));
    }
  }, [paymentPhotos, payments]);

  return hasValidPaymentPhotos;
};

const useValidSAVPhotos = (SAVPhotos) => {
  const [hasValidSAVPhotos, setHasValidSAVPhotos] = useState(false);

  useEffect(() => {
    setHasValidSAVPhotos(SAVPhotos.some((photo) => !photo.remove));
  }, [SAVPhotos]);

  return hasValidSAVPhotos;
};

const useHasRoundStarted = (roadmap, delivery) => {
  const [hasRoundStarted, setHasRoundStarted] = useState(true);

  useEffect(() => {
    const deliveryGroups = ["am", "pm"];
    const group = deliveryGroups.reduce((found, group) => {
      if (!found) {
        const groupOrders = roadmap[group];
        if (
          Array.isArray(groupOrders) &&
          groupOrders.map((delivery) => delivery.id).indexOf(delivery.id) > -1
        ) {
          return group;
        }
      }
      return found;
    }, null);

    setHasRoundStarted(
      {
        ...roadmap.started,
      }[group] === true
    );
  }, [roadmap, delivery]);

  return hasRoundStarted;
};

const useDeliveryState = (roadmap, delivery) => {
  const { setTitle } = useAppTitle();
  const history = useHistory();
  const isOnline = useNetworkStatus();
  const articleCategories = delivery.order.articles.map(
    ({ article }) => article.category?.name
  );
  const {
    orderArticles,
    setOrderArticles,
    setOrderArticleDeliveryStatus,
    setOrderArticleFailureReason,
    setOrderArticleMetadataDeliveryStatus,
    setOrderArticleMetadataFailureReason,
    resetOrderArticles,
  } = useOrderArticles(delivery.order.articles);

  useEffect(() => {
    setTitle(
      `${delivery.order.store_return ? "Retour" : "Commande"} n° ${
        delivery.order.id
      }`
    );
  }, [delivery, setTitle]);

  // formState
  const [comment, setComment] = useState(delivery.comment || "");
  const [arrivalTime, setArrivalTime] = useState(delivery.arrivalTime || "");
  const [payments, setPayments] = useState(delivery.payments);
  const [paymentPhotos, setPaymentPhotos] = useState(delivery.paymentPhotos);
  const [SAVPhotos, setSAVPhotos] = useState(delivery.SAVPhotos);
  const [SAVComment, setSAVComment] = useState(delivery.SAVComment || "");
  const [qualitySheet, qualitySheetActions] = useQualitySheet();
  const [isBypassed, setIsBypassed] = useState(false);
  const [failureReason, setFailureReason] = useState("");
  const [customFailureReason, setCustomFailureReason] = useState("");

  // Display triggers
  const [showConfirm, setShowConfirm] = useState(false);
  const [showQualitySheet, setShowQualitySheet] = useState(false);
  const [showTruckChange, setShowTruckChange] = useState(false);
  const [showCancelScreen, setShowCancelScreen] = useState(false);
  const [isDeliveryFailed, setIsDeliveryFailed] = useState(false);
  const [
    shouldAskBeforeLeavingDelivery,
    setShouldAskBeforeLeavingDelivery,
  ] = useState(!Boolean(delivery.delivered));

  // Misc validations
  const hasValidPaymentPhotos = useValidPaymentPhotos(payments, paymentPhotos);
  const hasValidSAVPhotos = useValidSAVPhotos(SAVPhotos);
  const hasRoundStarted = useHasRoundStarted(roadmap, delivery);
  const [qualitySheetErrors, setQualitySheetErrors] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [validateTimeout, setValidateTimeout] = useState(null);
  const [
    validateOrder,
    { loading: validateLoading, error: validateError },
  ] = useMutation(VALIDATE_ORDER, {
    onCompleted: ({ validateOrder }) => {
      if (validateOrder.success) {
        history.push("/");
      }

      if (validateOrder.message) {
        setErrorMessage(validateOrder.message);
      }
    },
  });

  const handleSubmit = () => {
    if (!isDeliveryFailed) {
      qualitySheetSchema(qualitySheet.bypass.reponse)
        .validate(qualitySheet, {
          abortEarly: false,
          context: {
            articleCategories: articleCategories || [],
          },
        })
        .then(() => {
          setShowConfirm(true);
          setQualitySheetErrors(null);
        })
        .catch((e) => {
          setQualitySheetErrors(getValidationErrors(e.inner));
          alert("Ce formulaire contient des erreurs");
        });
    } else {
      setShowConfirm(true);
    }
  };

  const validateDelivery = (confirm) => {
    setShowConfirm(false);
    setErrorMessage(null);

    if (confirm) {
      const formattedOrderArticles = Object.keys(orderArticles).map(
        (orderArticleKey) => {
          const orderArticle = {
            ...orderArticles[orderArticleKey],
            article_id: orderArticles[orderArticleKey].article.id,
            is_delivered: orderArticles[orderArticleKey].isDelivered,
            metadata: orderArticles[orderArticleKey].metadata.map(
              (metadatum) => {
                const formattedMetadatum = {
                  ...metadatum,
                  failure_reason:
                    metadatum.failure_reason ||
                    failureReason ||
                    customFailureReason ||
                    null,
                };

                delete formattedMetadatum.__typename;

                return formattedMetadatum;
              }
            ),
          };

          /* Cleanup unused attributes */
          delete orderArticle.isDelivered;
          delete orderArticle.failureReason;
          delete orderArticle.article;

          return orderArticle;
        }
      );

      const deliveryData = {
        delivered: utcNow(),
        arrival_time: arrivalTime,
        comment,
        sav_comment: SAVComment,
        payment_photos: preparePhotosForSubmit(paymentPhotos),
        sav_photos: preparePhotosForSubmit(SAVPhotos),
        some_article_have_not_been_delivered:
          isDeliveryFailed ||
          formattedOrderArticles.some((orderArticle) =>
            orderArticle.metadata.some((metadatum) => !metadatum.is_delivered)
          ),
        order_articles: formattedOrderArticles,
        is_delivery_failed:
          isDeliveryFailed ||
          formattedOrderArticles.every((orderArticle) =>
            orderArticle.metadata.every((metadatum) => !metadatum.is_delivered)
          ),
        failure_reason: customFailureReason || failureReason,
      };

      const { orderId, signature, ...details } = qualitySheet;

      const bypass_reason = isDeliveryFailed
        ? failureReason || customFailureReason
        : null;

      const orderQualitySheet = {
        response: JSON.stringify(details),
        signature,
        bypass_reason: qualitySheet.bypass.reponse
          ? qualitySheet.bypass.motif
          : bypass_reason,
      };

      const orderPayments = [...payments].map((payment) => {
        if (payment._new) {
          delete payment.id;
          delete payment._new;
        }
        return { ...payment, paid_amount: Number(payment.paid_amount) };
      });

      const data = {
        ...deliveryData,
        delivery_payments: orderPayments,
        quality_sheet: orderQualitySheet,
      };

      setShowCancelScreen(true);
      setValidateTimeout(
        setTimeout(() => {
          setShowCancelScreen(false);
          setShowQualitySheet(false);
          validateOrder(
            makeValidateOrderOptions(roadmap, delivery.order.id, data)
          );
          if (!errorMessage) {
            history.push("/");
          }
        }, cancelScreenTimeout)
      );
    }
  };

  const cancelValidateDelivery = () => {
    setShowCancelScreen(false);
    clearTimeout(validateTimeout);
  };

  return {
    delivery,
    display: {
      showConfirm,
      showQualitySheet,
      showTruckChange,
      showCancelScreen,
      isLoading: validateLoading,
      isOnline,
      isDeliveryFailed,
      shouldAskBeforeLeavingDelivery,
    },
    validations: {
      hasValidPaymentPhotos,
      hasValidSAVPhotos,
      isValidated: Boolean(delivery.delivered),
      hasRoundStarted,
      qualitySheetErrors,
      validateError: Boolean(validateError),
      errorMessage,
    },
    formState: {
      comment,
      SAVComment,
      SAVPhotos,
      paymentPhotos,
      payments,
      qualitySheet,
      isBypassed,
      failureReason,
      customFailureReason,
      orderArticles,
      arrivalTime,
    },
    actions: {
      setComment,
      setPayments,
      setPaymentPhotos,
      setSAVPhotos,
      setSAVComment,
      setArrivalTime,
      setShowConfirm,
      setShowQualitySheet,
      setShowTruckChange,
      setShowCancelScreen,
      setIsBypassed,
      qualitySheetActions,
      handleSubmit,
      validateDelivery,
      cancelValidateDelivery,
      setIsDeliveryFailed,
      setFailureReason,
      setCustomFailureReason,
      setOrderArticles,
      setOrderArticleDeliveryStatus,
      setOrderArticleFailureReason,
      setOrderArticleMetadataDeliveryStatus,
      setOrderArticleMetadataFailureReason,
      setShouldAskBeforeLeavingDelivery,
      resetOrderArticles,
    },
  };
};

export default useDeliveryState;
