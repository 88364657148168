import React from "react";
import getVideoStream from "utils/getVideoStream";

const usePhoto = (videoRef) => {
  const [stream, setStream] = React.useState(null);
  const [ready, setReady] = React.useState(false);

  const prepareVideoElement = React.useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    const videoElement = videoRef.current;
    if (videoElement instanceof HTMLVideoElement) {
      videoElement.setAttribute("autoplay", "true");
      videoElement.setAttribute("muted", "true");
      videoElement.setAttribute("playsinline", "true");
      return videoElement;
    }
  }, [videoRef]);

  const addVideoSource = React.useCallback((videoElement, stream) => {
    // Older browsers may not have `srcObject`
    try {
      // throws Exception if interrupted by a new loaded request
      videoElement.srcObject = stream;
    } catch (err) {
      // Fallback, note: we should avoid using this in new browsers, as it is going away.
      videoElement.src = URL.createObjectURL(stream);
    }
  }, []);

  const addReadyCallback = React.useCallback((videoElement, fn) => {
    videoElement.addEventListener("playing", () => setReady(true));
  }, []);

  const addReadyCallbackAsync = React.useCallback(
    (videoElement) => {
      return new Promise((resolve) =>
        addReadyCallback(videoElement, () => resolve())
      );
    },
    [addReadyCallback]
  );

  const attachStreamToVideo = React.useCallback(
    async (stream) => {
      const videoElement = prepareVideoElement();
      addVideoSource(videoElement, stream);
      await addReadyCallbackAsync(videoElement);

      return videoElement;
    },
    [prepareVideoElement, addVideoSource, addReadyCallbackAsync]
  );

  const startVideo = React.useCallback(async () => {
    const stream = await getVideoStream({
      video: { facingMode: "environment" },
    });
    setStream(stream);
    attachStreamToVideo(stream);
  }, [setStream, attachStreamToVideo]);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const takePhoto = async () => {
    if (stream && ready && videoRef.current) {
      const videoElement = videoRef.current;

      // Create a canvas element to capture the image
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      // Draw the current frame from the video onto the canvas
      const context = canvas.getContext("2d");
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      // Convert the canvas image to a Blob to keep the same logic
      return new Promise((resolve) => {
        canvas.toBlob(async (blob) => {
          // Convert the Blob to a Base64 string
          const imageBase64 = await blobToBase64(blob);
          resolve(imageBase64);
        }, "image/jpeg");
      });
    }
  };

  const stopStreams = React.useCallback(() => {
    stream.getVideoTracks().map((track) => track.stop());
  }, [stream]);

  const stopVideo = React.useCallback(() => {
    if (videoRef.current && stream?.active) {
      const videoElement = videoRef.current;

      stopStreams();
      videoElement.srcObject = null;
      setStream(null);
    }
  }, [videoRef, stream, setStream, stopStreams]);

  return { startVideo, stopVideo, takePhoto };
};

export default usePhoto;
